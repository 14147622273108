import React from "react";
import "./process.scss";
import loopStart from "../../../images/loop-start.svg";
import loopEnd from "../../../images/loop-end.svg";
import loop1 from "../../../images/loop-1.svg";
import loop2 from "../../../images/loop-2.svg";
import loop3 from "../../../images/loop-2.svg";
import { ScrollRotate } from "react-scroll-rotate";
import iconDiscovery from "../../../images/icon-discovery.svg";
import iconBuild from "../../../images/icon-build.svg";
import iconLaunch from "../../../images/icon-launch.svg";
import iconDesign from "../../../images/icon-design.svg";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Process extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callbackMount: this.props.callbackMount
    }
  }

  componentDidMount() {
    this.state.callbackMount('process');
  }

  render() {
    return (
      <div className="section process" data-header-color="dark">
        <div className="process__wrapper">
          <h2 className="process__title">
            <span style={{ color: "#F86D5C" }}>
              <FormattedMessage id="process.title.0" />
            </span>{" "}
            <FormattedMessage id="process.title.1" />
          </h2>
          <div className="pattern__process"></div>

          <div className="loop__img">
            <div className="brief__label">
              <FormattedMessage id="process.schema.0" />
            </div>
            <div className="design__label">
              <FormattedMessage id="process.schema.1" />
            </div>
            <div className="build__label">
              <FormattedMessage id="process.schema.2" />
            </div>
            <div className="launch__label">
              <FormattedMessage id="process.schema.3" />
            </div>
            <img src={loop1} className="loop1" alt="Saastory Process" />
            <div className="loop2-wrapper">
              <ScrollRotate animationDuration={1.5}>
                <img src={loop2} className="loop2" alt="Saastory Process" />
              </ScrollRotate>
            </div>
            <div className="loop3-wrapper">
              <ScrollRotate animationDuration={1.5}>
                <img src={loop3} className="loop3" alt="Saastory Process" />
              </ScrollRotate>
            </div>

            <img src={loopStart} className="loopstart" alt="Saastory Process" />
            <img src={loopEnd} className="loopend" alt="Saastory Process" />
          </div>
          <div className="steps__wrapper">
            <div className="section__process">— Our Capacities</div>
            <div className="steps">
              <div className="steps__item">
                <img
                  className="animation-container"
                  src={iconDiscovery}
                  alt="Saastory Process"
                />
                <div className="steps__item__title" title="1">
                  <FormattedMessage id="capacity__dicovery.title" />
                </div>
                <div className="steps__item__desc">
                  <li>
                    <FormattedMessage id="capacity__dicovery.li.0" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__dicovery.li.1" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__dicovery.li.2" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__dicovery.li.3" />
                  </li>
                </div>
              </div>
              <div className="steps__item">
                <img
                  className="animation-container"
                  src={iconDesign}
                  alt="Saastory Process"
                />
                <div className="steps__item__title" title="2">
                  <FormattedMessage id="capacity__design.title" />
                </div>
                <div className="steps__item__desc">
                  <li>
                    <FormattedMessage id="capacity__design.li.0" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__design.li.1" />{" "}
                  </li>
                  <li>
                    <FormattedMessage id="capacity__design.li.2" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__design.li.3" />
                  </li>
                </div>
              </div>
              <div className="steps__item">
                <img
                  className="animation-container"
                  src={iconBuild}
                  alt="Saastory Process"
                />
                <div className="steps__item__title" title="3">
                  <FormattedMessage id="capacity__build.title" />
                </div>
                <div className="steps__item__desc">
                  <li>
                    <FormattedMessage id="capacity__build.li.0" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__build.li.1" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__build.li.2" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__build.li.3" />
                  </li>
                </div>
              </div>
              <div className="steps__item">
                <img
                  className="animation-container"
                  src={iconLaunch}
                  alt="Saastory Process"
                />
                <div className="steps__item__title" title="4">
                  <FormattedMessage id="capacity__launch.title" />
                </div>
                <div className="steps__item__desc">
                  <li>
                    <FormattedMessage id="capacity__launch.li.0" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__launch.li.1" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__launch.li.2" />
                  </li>
                  <li>
                    <FormattedMessage id="capacity__launch.li.3" />
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } 
}

export default injectIntl(Process);